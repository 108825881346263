import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo, FAQJsonLd, ArticleJsonLd } from 'gatsby-plugin-next-seo';

import BodyContent from "../layouts/bodyContent/BodyContent"
import CasinosList from "../layouts/casinosList/CasinosList"

import { generateFaqItems, getFirstParagraphFromAllContentComponents } from '../utils/schemes';

const images = {
  "pelikasinot": "https://hallinta.kasinot.casino/wp-content/uploads/2022/12/pelikasinot.webp",
  "pay-n-play": "https://hallinta.kasinot.casino/wp-content/uploads/2022/12/pelikasinot.webp",
  "ilman-rekisteroitymista": "https://kasinot.casino/static/cdc941e158914a5888e940863e990f4a/kasinot-ilman-rekisteroitymista.webp",
  "uudet-kasinot": "https://hallinta.kasinot.casino/wp-content/uploads/2022/12/uudet-kasinot.webp",
  "home-page": "https://hallinta.kasinot.casino/wp-content/uploads/2022/12/kasinot.webp"
}

const SitePage = props => {
  const { pageBuilder, slug, modifiedGmt, dateGmt, title } = props?.data?.page;
  const SEOdata = pageBuilder.layouts[0]

  const firstParagraph = getFirstParagraphFromAllContentComponents(
    pageBuilder.layouts,
    pageBuilder.title === "Home page" ? "home" : null
  );

  const headline = title === "Home page"
  ? "Pelikasinot"
  : title

  const slugId = slug === "home-page" ? "" : `${slug}/`;
  return (
    <Layout
      bodyClass={`page-template-default page wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo
        title={SEOdata?.seoTitle}
        description={SEOdata?.seoDescription}
      />
      <ArticleJsonLd
          url={`https://kasinot.casino/${slugId}`}
          headline={headline}
          images={[
            images[slug] || "https://kasinot.casino/images/kasinot-casino.png"
          ]}
          datePublished={dateGmt}
          dateModified={modifiedGmt}
          proficiencyLevel="Expert"
          authorName='kasinot.casino päätoimittaja'
          publisherName='Kasinot casino'
          publisherLogo='https://kasinot.casino/images/kasinot-casino.png'
          description={firstParagraph}
          overrides={{
            '@type': 'TechArticle', // set's this as a blog post.
          }}
        />
      <div>
        {pageBuilder?.layouts.map((layout => {
          const type = layout.fieldGroupName
          if (type === "Page_Pagebuilder_Layouts_BodyText") {
            return (
              <BodyContent data={layout?.bodyText} />
            )
          } else if (type === "Page_Pagebuilder_Layouts_CasinosList") {
            return (
              <CasinosList
                casinosList={layout?.listCasinos}
                title={layout?.listTitle}
              />
            )
          } else if (type === "Page_Pagebuilder_Layouts_FaqSchema") {
            return (
              <FAQJsonLd 
                questions={generateFaqItems(layout.faqItem)}
              />
            )
          }
        }))}
      </div>
    </Layout>
  )
}

export default SitePage

export const query = graphql`
query FrontPage($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      slug
      modifiedGmt(formatString: "YYYY-MM-DD")
      dateGmt(formatString: "YYYY-MM-DD")
      title
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_CasinosList {
            fieldGroupName
            listTitle
            listCasinos {
              affiliateLinkki
              arvostelu
              bonukset
              casinoName
              fieldGroupName
              ilmaiskierrokset
              teksti
              termsLink
              casinoLogo {
                mediaItemUrl
              }
            }
          }
          ... on WpPage_Pagebuilder_Layouts_BodyText {
            bodyText
            fieldGroupName
          }
          ... on WpPage_Pagebuilder_Layouts_SeoComponent {
            fieldGroupName
            seoDescription
            seoTitle
          }
          ... on WpPage_Pagebuilder_Layouts_FaqSchema {
            fieldGroupName
            faqItem {
              faqanswer
              faqquestion
            }
          }
        }
      }
    }
  }
`
